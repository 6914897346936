<template>
  <Dialog title="Novi korisnik" :visible="isCreateDialogVisible" :show-close="false">
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <FormItem prop="item.email" label="E-mail">
          <Input
            v-model="formData.item.email"/>
        </FormItem>
        <FormItem prop="item.password" label="Lozinka">
          <Input
            v-model="formData.item.password"
            type="password"/>
        </FormItem>
        <FormItem prop="item.content.name" label="Ime">
          <Input
            v-model="formData.item.content.name"/>
        </FormItem>
        <FormItem prop="item.content.surname" label="Prezime">
          <Input
            v-model="formData.item.content.surname"/>
        </FormItem>
        <FormItem prop="item.isVerified" label="Verifikacija">
          <Select v-model="formData.item.isVerified">
            <Option :label="'Da'" :value="true"></Option>
            <Option :label="'Ne'" :value="false"></Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            native-type="submit"
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Input, Select, Option, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationUsersRootModalsCreate',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Input,
    Select,
    Option,
    Button,
    Dialog
  },
  props: [
    'isCreateDialogVisible'
  ],
  data() {
    return {
      formData: {
        item: {
          email: '',
          password: '',
          content: {
            name: '',
            surname: ''
          },
          isVerified: false
        }
      },
      rules: {
        item: {
          email: [
            {
              required: true,
              message: 'Molimo ispunite polje',
              trigger: ['blur']
            }
          ],
          password: [
            {
              required: true,
              message: 'Molimo ispunite polje',
              trigger: ['blur']
            }
          ],
          content: {
            name: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ],
            surname: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ]
          }
        }
      }
    }
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm(formName);
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post('administration/user/create', {
        email: this.formData.item.email,
        password: this.formData.item.password,
        content: JSON.stringify(this.formData.item.content),
        is_verified: this.formData.item.isVerified
      }).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$emit('getItems');
        this.$emit('closeDialog');
      });
    },
    setData(formName) {
      this.formData = {
        item: {
          email: '',
          password: '',
          content: {
            name: '',
            surname: ''
          },
          isVerified: false
        }
      };
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>
