<template>
  <Dialog custom-class="confirmation" title="Uklanjanje" :visible="isRemoveDialogVisible" :show-close="false">
    <div class="message">
      Jeste li sigurni?
    </div>
    <div class="form">
      <Form :model="formData" ref="componentForm" @submit.native.prevent="submitForm('componentForm')">
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            native-type="submit"
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"/>
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, Button, Dialog} from 'element-ui';

// Logic
export default {
  name: 'AdministrationUsersRootModalsRemove',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    Button,
    Dialog
  },
  props: [
    'operatingItem',
    'isRemoveDialogVisible'
  ],
  data() {
    return {
      formData: {
        item: {
          id: '',
          email: '',
          content: {
            name: '',
            surname: ''
          },
          isVerified: false
        }
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post(`administration/user/${this.formData.item.id}/remove`).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$emit('getItems');
        this.$emit('closeDialog');
      });
    },
    setData(formName) {
      this.formData = {
        item: {
          id: '',
          email: '',
          content: {
            name: '',
            surname: ''
          },
          isVerified: false
        }
      };
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    operatingItem() {
      this.formData = {
        item: {
          id: this.operatingItem.id,
          email: this.operatingItem.email,
          content: {
            name: this.operatingItem.content.name,
            surname: this.operatingItem.content.surname
          },
          isVerified: this.operatingItem.isVerified
        }
      };
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>
